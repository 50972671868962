import "./progress.css";

// eslint-disable-next-line react/prop-types
const Progress = ({ children }) => {
  return (
    <div
      className="RadialProgress"
      role="progressbar"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {children}
    </div>
  );
};

export default Progress;
