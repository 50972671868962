/* eslint-disable react/display-name */
import { memo } from "react";
import Lottie from "react-lottie-player";

const LottiePlayer = memo((props) => {
  // eslint-disable-next-line react/prop-types
  const { json, loop = true, play = true } = props;

  return (
    <div>
      <Lottie
        loop={loop}
        speed={1}
        play={play}
        animationData={json}
        direction={1}
      />
    </div>
  );
});

export default LottiePlayer;
