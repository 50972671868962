import PropTypes from "prop-types";
import clsx from "clsx";
import "./image-eraser.css";
import useMouse from "@react-hook/mouse-position";
import { useEffect, useRef } from "react";
import { Actions } from "../actions";

const ImageEraser = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className, progress, isWin, currentTime, toggleHint } = props;

  const ref = useRef(null);
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const cursor = document.getElementById("cursor");
    const cursorWidth = cursor.width;

    const { clientX, clientY, isOver } = mouse;

    const gap = clientY;
    if (isOver) {
      cursor.style.transform = `translate(${
        clientX + cursorWidth + (isMobile ? 40 : 0)
      }px, ${gap}px)`;
      setTimeout(() => {
        cursor.style.opacity = 1;
      }, 100);
    } else {
      setTimeout(() => {
        cursor.style.opacity = 0;
      }, 100);
    }
  }, [mouse]);

  return (
    <div
      className={clsx("image-eraser", className)}
      style={{ pointerEvents: isWin ? "none" : "all" }}
    >
      <div className="image-eraser__content">
        <img className="artefact" src="/img/spray-2.png" />
        <div className="image-wrapper" ref={ref}>
          <img id="oven" src="/img/oven-cover.png" alt="oven-cover" />
          <img id="oven-cover" src="/img/oven.png" alt="oven" />
          <div id="shadow"></div>
        </div>
        <Actions
          progress={progress}
          currentTime={currentTime}
          toggleHint={toggleHint}
        />
      </div>
    </div>
  );
};

ImageEraser.propTypes = {
  className: PropTypes.string,
};

ImageEraser.defaultProps = {
  className: "",
};

export default ImageEraser;
