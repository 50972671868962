/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";

const Timer = ({ className, currentTime }) => {
  const getTime = (ct) => {
    const minutes = moment(ct).format("mm");
    const seconds = moment(ct).format("ss");

    const secs = Number(minutes) * 60 + Number(seconds);

    return `${secs}.${moment(ct).format("SS")}`;
  };

  return (
    <div className={className}>
      <span>{currentTime ? getTime(currentTime) : "0.00"}</span>
    </div>
  );
};
export default Timer;
