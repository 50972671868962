/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from "react";

export const StateContext = createContext(null);

export default function AppStateProvider(props) {
  const { children } = props;

  const [profile, setProfile] = useState(null);

  React.useEffect(() => {
    if (window.FB && window.FB.getAuthResponse) {
      responseFacebook(window.FB.getAuthResponse());
    }
  }, []);

  const responseFacebook = (response) => {
    if (response && response.name) {
      setProfile(response);
    }
  };

  return (
    <StateContext.Provider
      value={{
        profile,
        responseFacebook,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
