import "./header.css";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as RankIcon } from "./rank.svg";
import clsx from "clsx";
import { ReactComponent as LogoIcon } from "./logo.svg";

const Header = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClickRankingIconButton, isRankingOverlayOpen } = props;

  return (
    <header className="header">
      <img src="/img/god-hand.png" className="god-hand" />
      <h1 className="header__title">
        <LogoIcon />
        ხარისხი არის ბაგი!
      </h1>
      <div className="pane">
        {!isRankingOverlayOpen ? (
          <button
            onClick={onClickRankingIconButton}
            className={clsx("btn btn--ranking")}
          >
            <RankIcon />
            რეიტინგი
          </button>
        ) : (
          <button
            onClick={onClickRankingIconButton}
            className={clsx("btn btn--close")}
          >
            <CloseIcon className="icon" />
          </button>
        )}
        <button className={clsx("btn btn--policy")}>წესები</button>
      </div>
    </header>
  );
};

export default Header;
