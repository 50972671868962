/* eslint-disable react/prop-types */
import clsx from "clsx";
import "./ranking-overlay.css";

const RaningOverlay = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className, winners } = props;

  const getTime = (time) => {
    const [minutes, seconds, miliseconds] = time.split(":");

    const secs = Number(minutes) * 60 + Number(seconds);

    return `${secs}.${miliseconds}`;
  };

  return (
    <div className={clsx("overlay overlay--info", className)}>
      <div className="info">
        <img src="/img/top-10.png" />
        <ul>
          {winners.map((winner, index) => (
            <li key={winner.id}>
              <span>{index + 1}:</span>
              <div>
                {winner.name}: <span>{getTime(winner.detailedTime)}წმ</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RaningOverlay;
