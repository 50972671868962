const messageTranslation = {
  unknown_response: "Unknown error response from connector",
  not_found: "Not found",
  "TypeError: Failed to fetch": "Cannot get a response from connector.",
};

export const handleFetch = (resolve, reject) => {
  return {
    xthen: (response) => {
      const contentType = response.headers["content-type"];
      const contentDisp = response.headers["content-disposition"];
      const isJson = /(application|text)\/(json|plain)/.test(contentType);
      const isOffice =
        /(application)\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/.test(
          contentType
        );
      const isCsv = /(application)\/csv/.test(contentType);
      const isAttachment = /attachment/.test(contentDisp);
      const isEmpty = response.headers["content-length"] === "0";

      if (Number(response.status) !== 200 && Number(response.status) !== 201) {
        if (Number(response.status) === 204) {
          throw Error(messageTranslation.not_found);
        }

        if (isJson) {
          throw response.json();
        }
        throw Error(messageTranslation.unknown_response);
      }

      if (isAttachment) {
        response.blob().then((blob) => {
          resolve(blob);
        });
        return;
      }

      if (isJson || isOffice || isCsv || isEmpty) {
        resolve(response.data);
      }
      resolve(true);
    },
    xsthen: (response) => {
      resolve(response);
    },
    xcatch: (errorResponse) => {
      // is thrown json
      if (errorResponse && errorResponse.then) {
        errorResponse.then((errJson) => {
          return reject(errJson.errorMsg || JSON.stringify(errJson));
        });
      } else {
        return reject(messageTranslation[errorResponse] || errorResponse);
      }
      return true;
    },
  };
};
