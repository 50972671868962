import anime from "animejs";
import { useEffect } from "react";

export const useAnimation = () => {
  useEffect(() => {
    var extradelay = 1000;
    const loader = document.querySelector(".overlay--loader");
    const imageContainer = document.querySelector(".image-eraser");
    const header = document.querySelector(".header");

    anime({
      targets: loader,
      duration: 600,
      easing: "easeInOutCubic",
      delay: extradelay,
      translateY: "-100%",
      complete: function () {
        loader.classList.remove("overlay--active");
      },
    });
    anime({
      delay: 1400,
      targets: imageContainer,
      duration: 600,
      easing: "easeInOutCubic",
      opacity: [0, 1],
    });
    anime({
      delay: 1600,
      targets: header,
      duration: 600,
      easing: "easeInOutCubic",
      opacity: [0, 1],
    });
  }, []);
};
