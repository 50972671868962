import { handleFetch } from "../handler";
import * as API from "./winnerApi";

export const loadWinners = () => {
  return new Promise((resolve, reject) => {
    return API.getWinners()
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const createWinner = (details) => {
  return new Promise((resolve, reject) => {
    return API.createWinner(details)
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const loadWinnerPosition = (time, facebookId) => {
  return new Promise((resolve, reject) => {
    return API.winnerPosition(time, facebookId)
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};

export const loadWinnerPoints = (facebookId) => {
  return new Promise((resolve, reject) => {
    return API.winnerPoints(facebookId)
      .then(handleFetch(resolve, reject).xthen)
      .catch(handleFetch(resolve, reject).xcatch);
  });
};
