/* eslint-disable react/display-name */
import React, { memo } from "react";
import "./actions.css";
import { ReactComponent as CountDownIcon } from "./countdown.svg";
import Timer from "./Timer";
import { Progress } from "../progress";
import { ReactComponent as LogoIcon } from "./logo.svg";

const Actions = memo((props) => {
  // eslint-disable-next-line react/prop-types
  const { progress, currentTime, onChangeFormatDate, formatCount } = props;

  return (
    <div>
      <div className="actions">
        <div className="logo-wrapper">
          <LogoIcon />
        </div>

        <div className="count-down">
          <CountDownIcon />
          <Timer
            onChangeFormatDate={onChangeFormatDate}
            formatCount={formatCount}
            currentTime={currentTime}
            className="p-time"
          />
        </div>
        <div className="progress" style={{ "--progress": `${progress}%` }}>
          <Progress>{progress}%</Progress>
        </div>
      </div>
    </div>
  );
});

export default Actions;
