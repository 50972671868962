/* eslint-disable react/prop-types */
import "./submit-score.css";
import clsx from "clsx";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { ReactComponent as FacebookIcon } from "./facebook.svg";
import { useAppState } from "../../state";
import {
  loadWinnerPosition,
  loadWinnerPoints,
} from "../../api/winner/winnerApiHandler";
import { useEffect, useState } from "react";

const SubmitScore = (props) => {
  const { className, count, formatCount, onClose, onSubmit } = props;

  const [copyTextVariant, setCopyTextVariant] = useState(0);
  const [position, setPosition] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userTime, setUserTime] = useState(null);

  const state = useAppState();

  const responseFacebook = (response) => {
    state.responseFacebook(response);
  };

  useEffect(() => {
    const fetchWinnerPosition = async () => {
      try {
        const { position } = await loadWinnerPosition(count);

        if (Number(position) > 10) {
          setCopyTextVariant(0);
        }
        if (Number(position) < 10) {
          setCopyTextVariant(1);
        }
        if (Number(position) === 1) {
          setCopyTextVariant(2);
        }

        setPosition(position);
        setIsLoaded(true);
      } catch (err) {
        throw new Error(err);
      }
    };

    fetchWinnerPosition();
  }, []);

  useEffect(() => {
    if (state.profile && state.profile.id) {
      const fetchWinnerPoints = async () => {
        try {
          const { time } = await loadWinnerPoints(state.profile.id);

          setUserTime(time);
        } catch (err) {
          console.log(err);
        }
      };
      fetchWinnerPoints();
    }
  }, [state]);

  const handleSubmit = () => {
    onSubmit({
      name: state.profile.name,
      facebookId: state.profile.id,
      facebookProfileLink: state.profile.link,
      email: state.profile.email,
    });
  };

  const getTime = (time) => {
    const [minutes, seconds, miliseconds] = time.split(":");

    const secs = Number(minutes) * 60 + Number(seconds);

    return `${secs}.${miliseconds}`;
  };

  return (
    <div className={clsx("overlay overlay--rank", className)}>
      <div className="submit-wrapper">
        {isLoaded ? (
          <>
            <div>
              {copyTextVariant === 0 && (
                <span className="details">
                  🎉გილოცავ, ბაგის შუმანიტით შენ გაზქურა სულ რაღაც{" "}
                  <span>{getTime(formatCount)}</span> წამში დაასუფთავე 👏
                  <br />
                  <br />
                  ამ შედეგით რეიტნგში დაიკავე {position}-ე ადგილი 📊. თუ
                  გათამაშების ბოლომდე შენს შედეგს გააუმჯობესებ და ტოპ ათეულში
                  მოხვდები, ძალიან მაგარ საჩუქარსაც 🎁მიიღებ. შენი შედეგის
                  გასაუმჯობესებლად შეგიძლია სცადო ხელახლა.
                </span>
              )}
              {copyTextVariant === 1 && (
                <span className="details">
                  🎉😳ძალიან მაგარი ხარ, ბაგის შუმანიტით შენ გაზქურა სულ რაღაც{" "}
                  <span>{getTime(formatCount)} </span> წამში დაასუფთავე 👏👏
                  <br />
                  <br />
                  ამ შედეგით რეიტნგში დაიკავე {position}-ე ადგილი 📊და თუ
                  გათამაშების ბოლოსთვის ისევ ტოპ ათეულში იქნები ძალიან მაგარ
                  საჩუქარსაც 🎁🎁 მიიღებ. შენი შედეგის გასაუმჯობესებლად შეგიძლია
                  სცადო ხელახლა.
                </span>
              )}
              {copyTextVariant === 2 && (
                <span className="details">
                  🎉😳😱არ არსებობს, შენ ყველა რეკორდი მოხსენი და ბაგის
                  შუმანიტით გაზქურა ყველაზე სწრაფად{" "}
                  <span>{getTime(formatCount)} </span> წამში დაასუფთავე 👏👏💪
                  <br />
                  <br />
                  ამ შედეგით რეიტინგში დამსახურებულად დაიკავე პირველი ადგილი
                  🔝🥇 და თუ გათამაშების ბოლოსთვის ტოპ ათეულში იქნები ძალიან
                  მაგარ საჩუქარსაც 🎁🎁 მიიღებ. შენი შედეგის გასაუმჯობესებლად
                  შეგიძლია სცადო ხელახლა.
                </span>
              )}
              {!state.profile && (
                <div className="login-wrapper">
                  <span>
                    გათამაშებაში მონაწილეობისათვის გაიარეთ ავტორიზაცია.
                  </span>
                </div>
              )}
              {state.profile && userTime && (
                <div className="time-wrapper">
                  <span>თქვენი ამჟამინდელი შედეგი: {userTime}წმ</span>
                </div>
              )}

              <div className="form-actions">
                <button onClick={onClose} type="button" className="form-btn">
                  ხელახლა ცდა
                </button>

                {!state.profile && (
                  <FacebookLogin
                    appId="1569662113402344"
                    autoLoad={false}
                    callback={responseFacebook}
                    disableMobileRedirect={true}
                    fields="name,email,link"
                    render={(renderProps) => (
                      <button className="btn-fb" href="">
                        <div
                          onClick={renderProps.onClick}
                          className="fb-content"
                        >
                          <div className="logo">
                            <FacebookIcon />
                          </div>
                          <p>ავტორიზაცია</p>
                        </div>
                      </button>
                    )}
                  />
                )}
                {state.profile &&
                  userTime &&
                  Number(getTime(userTime)) > Number(getTime(formatCount)) && (
                    <button
                      type="submit"
                      className="form-btn"
                      onClick={handleSubmit}
                    >
                      შედეგის განახლება
                    </button>
                  )}
                {state.profile && !userTime && (
                  <button
                    type="submit"
                    className="form-btn"
                    onClick={handleSubmit}
                  >
                    შედეგის შენახვა
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          <span>Loading...</span>
        )}
      </div>
    </div>
  );
};

export default SubmitScore;
