/* eslint-disable no-undef */
import moment from "moment";
import clsx from "clsx";
import { useAnimation } from "./hooks/useAnimation";
import AppStateProvider from "./state";
import { useEffect, useState, useRef } from "react";
import { Header } from "./components/header";
import { ImageEraser } from "./components/image-eraser";
import { RankingOverlay } from "./components/ranking-overlay";
import { Loader } from "./components/loader";
import { SubmitScore } from "./components/submit-score";
import { WinAnim } from "./components/win-anim";
import { loadWinners, createWinner } from "./api/winner/winnerApiHandler";
import imagesLoaded from "imagesloaded";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

import "./App.css";

function App() {
  const [isRankingOverlayOpen, setIsRankingOverlayOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isWin, setIsWin] = useState(false);
  const [isSubmitOverlayVisible, setIsSubmitOverlayVisible] = useState(false);
  const [winners, setWinners] = useState([]);

  const [currentTime, setCurrentTime] = useState(null);

  const timeCounter = useRef(0);
  const runClock = useRef(null);
  const [isIntervalStarted, setIsIntervalStarted] = useState(false);

  const [isUpdating, setIsUpdating] = useState(true);
  const [isWithHint, setIsWithHint] = useState(null);

  useAnimation();

  useEffect(() => {
    let lastWindowWidth = 0;

    const erase = () => {
      imagesLoaded(document.querySelectorAll("img"), () => {
        $("#oven-cover").eraser({
          size: window.innerWidth <= 768 ? 30 : 60,
          progressFunction: (p) => {
            setProgress(Math.floor(p));
          },
        });
      });
    };

    erase();

    const resetThet = () => {
      setIsWithHint(false);
      setIsUpdating(false);

      setTimeout(() => {
        setIsUpdating(true);
        erase();
        document.querySelector(".image-eraser").style.opacity = 1;
      }, 1000);
    };

    window.addEventListener("resize", () => {
      if (lastWindowWidth <= 768 && window.innerWidth > 768) {
        resetThet();
      }
      if (lastWindowWidth > 768 && window.innerWidth <= 768) {
        resetThet();
      }
      if (lastWindowWidth <= 768 && window.innerWidth <= 768) {
        resetThet();
      }

      lastWindowWidth = window.innerWidth;
    });
  }, []);

  useEffect(() => {
    if (isWithHint === null) return;
    if (isWithHint) {
      document.querySelector("canvas").classList.add("hint-mode");
    } else {
      document.querySelector("canvas").classList.remove("hint-mode");
    }
  }, [isWithHint]);

  useEffect(() => {
    if (progress > 0) {
      if (!isIntervalStarted) {
        startWatch();
      }
    }

    if (progress >= 93) {
      $("#oven-cover").eraser("clear");
      setProgress(100);
      setIsWin(true);
      stopWatch();

      setTimeout(() => {
        setIsWin(false);
        setIsSubmitOverlayVisible(true);
      }, 3000);
    }
  }, [progress]);

  const displayTime = () => {
    timeCounter.current = timeCounter.current + 70;
    setCurrentTime(
      moment().hour(0).minute(0).second(0).millisecond(timeCounter.current)
    );
  };

  const startWatch = () => {
    setIsIntervalStarted(true);
    runClock.current = setInterval(displayTime, 70, true);
  };

  function stopWatch() {
    clearInterval(runClock.current);
  }

  const resetAll = () => {
    timeCounter.current = 0;
    setCurrentTime(null);
    setProgress(0);
    setIsIntervalStarted(false);
  };

  const handleClickRankingIconButton = async () => {
    const targetElement = document.querySelector(".overlay--info");

    const fetchWinners = async () => {
      const data = await loadWinners();
      setWinners(data);
    };
    if (isRankingOverlayOpen) {
      setIsRankingOverlayOpen(false);
      enableBodyScroll(targetElement);
    } else {
      fetchWinners();
      setIsRankingOverlayOpen(true);
      disableBodyScroll(targetElement);
    }
  };

  const handleClickCloseSubmitScore = () => {
    $("#oven-cover").eraser("reset");
    setIsSubmitOverlayVisible(false);

    resetAll();
  };

  const handleClickSubmitScore = async (details) => {
    // eslint-disable-next-line no-useless-catch
    try {
      await createWinner({
        ...details,
        time:
          +currentTime.format("x") -
          +moment().hour(0).minute(0).second(0).millisecond(0).format("x"),
        detailedTime: currentTime.format("mm:ss:SS"),
      });

      handleClickCloseSubmitScore();
    } catch (err) {
      throw err;
    }
  };

  return (
    <AppStateProvider>
      <div className="App">
        <Loader />
        <Header
          isRankingOverlayOpen={isRankingOverlayOpen}
          onClickRankingIconButton={handleClickRankingIconButton}
        />
        {isUpdating && (
          <ImageEraser
            className="image-eraser--full"
            isWin={isWin}
            progress={progress}
            currentTime={currentTime}
            toggleHint={() => setIsWithHint(!isWithHint)}
          />
        )}
        <RankingOverlay
          winners={winners}
          className={isRankingOverlayOpen ? "overlay--active" : ""}
        />
        {isSubmitOverlayVisible && (
          <SubmitScore
            className={clsx(isSubmitOverlayVisible ? "overlay--active" : "")}
            onClose={handleClickCloseSubmitScore}
            count={
              +currentTime?.format("x") -
              +moment().hour(0).minute(0).second(0).millisecond(0).format("x")
            }
            formatCount={currentTime?.format("mm:ss:SS")}
            onSubmit={handleClickSubmitScore}
          />
        )}
        <div
          className={clsx(
            "win-anim-wrapper",
            isWin ? "win-anim-wrapper--active" : ""
          )}
        >
          <WinAnim play={isWin} />
        </div>
        <img id="cursor" src="/img/spray.png" />
      </div>
    </AppStateProvider>
  );
}

export default App;
