import { axiosRemote as axios } from "../../utils/axios";
import config from "./config.js";

export const getWinners = () => {
  return axios.get(`${config.url_winners}?_sort=time:ASC&_limit=9`);
};

export const winnerPosition = (time, facebookId) => {
  return axios.get(
    `${config.url_winner_position}?time=${time}&facebookId=${facebookId}`
  );
};

export const createWinner = (details) => {
  return axios.post(
    `${config.url_winners}?facebookId=${details.facebookId}`,
    details
  );
};

export const winnerPoints = (facebookId) => {
  return axios.get(`${config.url_winner_points}?facebookId=${facebookId}`);
};
